import React from 'react';
import styled from 'styled-components';

const Spacer = styled.div`
  display: none;
`;

function SpacerField() {
  return <Spacer />;
}

export default SpacerField;
