import jsonLogic from 'json-logic-js';
import curry from 'lodash/curry';
import identity from 'lodash/identity';

/**
 * Checks the JsonLogic held in a schema against the current formValues
 * if the logic returns true we'll expect to render the step or field
 */
export const shouldRender = curry((dependsOn, formValues = {}) =>
  jsonLogic.apply(dependsOn, formValues)
);

/**
 * Returns an array of all the steps which are valid for
 * the current formValues. This can change based on user
 * input because we may have a branching form.
 */
export const generateSteps = curry((steps, formValues = {}) =>
  steps.filter(({ dependsOn }) =>
    // If the step has a `dependsOn` utility
    // we'll run the code (jsonLogic) against the current
    // form state to see if the step should show
    dependsOn ? shouldRender(dependsOn, formValues) : true
  )
);

/**
 * Generates an array of all the fields possible for the steps
 * passed and the current formState. Fields can be toggled on and
 * off by using the field.dependsOn which is JsonLogic
 */
export const generateFields = curry((steps, formValues = {}) =>
  steps
    .reduce((acc, { fields = [] }) => acc.concat(fields), [])
    .filter(identity)
    .filter(({ dependsOn }) =>
      // If the field has a `dependsOn` utility
      // we will run the code (which is jsonLogic)
      // against the current form state to see if this field should show
      dependsOn ? shouldRender(dependsOn, formValues) : true
    )
);

export const generateFieldsForStep = curry((step, formValues) =>
  generateFields([step], formValues)
);

export const generateFieldsUntilStep = curry((steps, untilStep, formValues) =>
  // Generates the form Schema for every step until the current (or specified)
  generateFields(steps.slice(0, untilStep), formValues)
);

export default {
  shouldRender,
  generateSteps,
  generateFields,
  generateFieldsForStep,
  generateFieldsUntilStep
};
