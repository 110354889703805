import React, { useState } from 'react';

import { Controller } from 'react-hook-form';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import fieldPropTypes from '../../../DynamicForm/PropTypes';
import FieldOfType from '../../../DynamicForm/componentRegistry';

const useStyles = makeStyles((theme) => ({
  button: {
    fontFamily: 'SansMeatProportional, sans-serif',
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontSize: '0.9rem'
  }
}));

function Toggleable({ field: toggleable, form, customized }) {
  const { field, name = 'NO_NAME', label, defaultValue } = toggleable;
  const classes = useStyles();
  const [openButton, setOpenButton] = useState();

  if (name === 'NO_NAME') {
    // eslint-disable-next-line no-console
    console.warn(`Toggleable Field ${label} has no name`);
  }

  return (
    <>
      <FieldOfType form={form} field={field} customized={customized} />
      <Controller
        name={name}
        control={form.control}
        defaultValue={Boolean(defaultValue)}
        render={({ field: { onChange, value } }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button
              className={classes.button}
              tabIndex="0"
              onClick={() => {
                const nextValue = !value;

                onChange(nextValue);
                setOpenButton(nextValue);

                // We should clear the old input when
                // we unmount the field so that we don't
                // end up with some strage [Object] text bug
                if (nextValue === false && field.name) {
                  form.setValue(field.name, undefined);
                }
              }}
              aria-pressed={openButton}
              aria-label={label}
            >
              {label}
            </Button>
          </div>
        )}
      />
    </>
  );
}

/**
 * We will toggle the entire component structure
 * off here. This should trigger an unmounting of the
 * form fields which removes the formState (react-hook-form)
 * */
Toggleable.willRender = ({ form, field }) =>
  Boolean(form.getValues()[field.name]) === Boolean(field.defaultValue);

Toggleable.propTypes = fieldPropTypes;

export default Toggleable;
