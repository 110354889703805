/**
 * **Sigh**
 * React hook form giving me trouble with boolean checkboxes
 * It returns one of 5 different value types depending on it's mood
 */
export function findBool(value) {
  if (value instanceof Array) {
    if (!value.length) return false;
    if (value[0] === 'on') return true;
    return false;
  }

  switch (value) {
    case 'false':
    case 'off':
      return false;

    case 'true':
    case 'on':
      return true;

    default:
      return Boolean(value);
  }
}

/**
 * Check validations array for any required type
 */
export function isRequired({ schema = {} }) {
  return schema.required === true;
}

export default { findBool, isRequired };
