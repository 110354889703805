/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';

import dynamic from 'next/dynamic';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller } from 'react-hook-form';

import fieldPropTypes from '../PropTypes';

import { isRequired } from './utils';
import { getLabel } from './LabelWrapper';

import { DateWrapper } from './styles';

const DatePicker = dynamic(() => import('react-datepicker'));
function DateRange({ field, form }) {
  const { placeholder, id, name, sublabel, defaultValue } = field;

  return (
    <Controller
      id={id}
      name={name}
      control={form.control}
      defaultValue={[defaultValue, defaultValue]}
      render={({ field: { onChange, value } }) => (
        <DateWrapper data-testid="test-DateRange">
          <label htmlFor={id}>{getLabel({ field, form })}</label>
          {sublabel && <span>{sublabel}</span>}
          <DatePicker
            onChange={(dates) => onChange(dates)}
            minDate={new Date()}
            endDate={value.length > 1 && value[1]}
            startDate={value.length > 0 && value[0]}
            placeholder={placeholder}
            required={isRequired(field)}
            selectsRange
            inline
          />
        </DateWrapper>
      )}
    />
  );
}

DateRange.propTypes = fieldPropTypes;

export default DateRange;
