import styled, { css } from 'styled-components';
import { colors, fonts } from '../../styles';

const inputStyling = css`
  background-color: transparent;
  border: 0;
  color: ${colors.nearBlack};
  font-family: ${fonts.standard};
  font-size: 1rem;
  // height: 1.625rem;
  outline: none;
  text-align: left;
  width: 100%;
  border-bottom: solid 1px rgba(0, 0, 0, 0.42);
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  ::placeholder {
    color: ${colors.black};
  }

  ${(props) =>
    props.hasIcon &&
    css`
      padding-left: 2rem;
    `}

  &:focus {
    outline: none;
    border-bottom: solid 1px ${colors.blue};
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
  &:hover {
    border-bottom: solid 1px ${colors.blue};
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
`;

export const InputUnderline = styled.input`
  ${inputStyling}
`;

export const InputWrapper = styled.div`
  ${(props) =>
    props.hasIcon &&
    css`
      display: flex;
      flex-direction: row;
      position: relative;

      div {
        position: absolute;
        bottom: 16px;
      }

      svg {
        max-width: 20px;
        max-height: 20px;
        width: 100%;
        * {
          // TODO: replace with theme variables
          fill: rgb(49, 65, 235);
        }
      }

      input {
        padding-left: 26px;
      }
    `}

  input {
    ${inputStyling}
  }
`;

export const ReCaptchaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;

  span {
    margin-bottom: 1.25rem;
  }

  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
`;

export default { DateWrapper };
