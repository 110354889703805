/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';

import { getLabel } from '../../../DynamicForm/FieldTypes/LabelWrapper';
import fieldPropTypes from '../../../DynamicForm/PropTypes';

import { colors } from '../../../styles';
import { findBool } from './utils';

const TextToggleButton = styled.span`
  height: auto;
  margin-bottom: 1rem;
  border-bottom: none;

  &:focus {
    outline: none;
  }

  color: ${colors.royalBlue};
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
  font-size: 0.9rem !important;

  ${({ alignRight }) => alignRight && 'justify-content: flex-end;'}
  ${({ alignLeft }) => alignLeft && 'justify-content: flex-start;'}
`;

function TextToggle({ field, form }) {
  const { id, name, align = 'right' } = field;
  const defaultValue = findBool(form.watch(name, field.defaultValue));

  return (
    <Controller
      id={id}
      name={name}
      type="checkbox"
      control={form.control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <TextToggleButton
          role="button"
          tabIndex="-1"
          alignLeft={align === 'left'}
          alignRight={align === 'right'}
          onClick={() => onChange(!value)}
        >
          {getLabel({ field, form })}
        </TextToggleButton>
      )}
    />
  );
}

TextToggle.propTypes = fieldPropTypes;

export default TextToggle;
