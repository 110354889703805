import { useState } from 'react';
import identity from 'lodash/identity';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';

import FastestValidator from 'fastest-validator';

function mergeErrors(errors) {
  // Form is A-OK!
  if (errors === true) return {};

  const toReturn = {};

  errors.forEach(({ field, message }) => {
    toReturn[field] = message;
  });

  return toReturn;
}

export function buildSchema(fields = []) {
  const toReturn = {};
  fields
    .filter(identity)
    .filter(({ schema, field = {} }) => schema || field.schema)
    .forEach(({ schema, name, field }) => {
      const toBuild = {};

      // We may have a nested field, Here we make sure
      // to include the schema for this field also
      if (field && field.schema) {
        toBuild[field.name] = field.schema;
      }

      // This is the top level schema
      if (name && schema) {
        toBuild[name] = schema;
      }

      return merge(toReturn, toBuild);
    });

  return toReturn;
}

export function useSchema(fields = []) {
  const [schema, setSchema] = useState(buildSchema(fields));

  const setFields = (newFields) => {
    const newSchema = buildSchema(newFields);

    if (isEqual(schema, newSchema)) return schema;

    return setSchema(buildSchema(newFields));
  };

  return [schema, setFields];
}

export default function useValidator(schema = {}) {
  const Validator = new FastestValidator({
    defaults: {
      string: {
        empty: false
      },
      number: {
        convert: true
      }
    }
  });

  let validator;
  try {
    Object.keys(schema).forEach((field) => {
      if (!Array.isArray(schema[field]) && typeof schema[field].type === 'undefined') {
        // eslint-disable-next-line
        schema[field].type = 'string';
      }
    });
    validator = Validator.compile(schema);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error while compiling schema:', schema);
    throw error;
  }

  return (values = {}) => {
    const result = validator(values);

    // No errors case
    if (result === true) {
      return { values, errors: {} };
    }

    // Filter only touched errors case
    return { values, errors: mergeErrors(result) };
  };
}
