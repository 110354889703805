import React from 'react';
import fieldPropTypes from '../PropTypes';

import Select from './Select';
import Countries from './Countries';

const mergeCountryOptions = (field) => ({
  ...field,
  options: Object.entries(Countries).map(([countryKey, country]) => ({
    label: country,
    value: country,
    key: countryKey
  }))
});

function SelectCountry({ field, form, customized }) {
  return <Select form={form} field={mergeCountryOptions(field)} customized={customized} />;
}

SelectCountry.propTypes = fieldPropTypes;

export default SelectCountry;
