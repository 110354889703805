/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { get, first, last, prop } from 'lodash/fp';
import { Controller } from 'react-hook-form';
import dynamic from 'next/dynamic';
import useTranslation from 'next-translate/useTranslation';

import fieldPropTypes from '../PropTypes';
import States from './States';
import LabelWrapper from './LabelWrapper';
import { isRequired } from './utils';

const Autocomplete = dynamic(() => import('react-google-places-autocomplete'));

export const SearchWrapper = styled(LabelWrapper)`
  span {
    display: flex;
    align-items: center;
  }

  div[class$='-placeholder'] ~ div[class^='css-'] {
    padding-top: 0;
    padding-bottom: 0;
  }

  div[class$='-placeholder'] {
    color: #000000;
  }

  input {
    margin: 0.8rem 0;
    padding: 0;
  }

  // Fix a bug where the select items are white on white
  > div > div:nth-of-type(2) {
    div {
      color: black;
    }
  }
`;

const stateValues = Object.values(States);

const { NODE_ENV } = process.env;
const API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;

function findState(country, addressLines) {
  if (typeof country !== 'string') return 'N/A';
  if (country.toUpperCase() !== 'USA') return 'N/A';

  const stateByAbbreviation = addressLines.find((name) => States[name]);
  if (stateByAbbreviation) return stateByAbbreviation;

  const stateByName = addressLines.find((name) => stateValues.includes(name));
  if (stateByName) return stateByName;

  return 'N/A';
}

function buildPlace({ value }, geocode, phone) {
  const { terms = [] } = value;
  const addressLines = terms.map(prop('value'));
  const company = first(addressLines);
  let zip;
  try {
    // Extract zip from geocode api
    if (
      geocode &&
      geocode.length > 0 &&
      geocode[0].address_components &&
      geocode[0].address_components.length > 0
    ) {
      const pCode = geocode[0].address_components.find((o) => o.types.includes('postal_code'));
      if (pCode) zip = pCode.long_name;
    }
  } catch (err) {
    console.info(err);
  }

  // Next lines will break when we have a city on the moon
  const country = last(addressLines);
  const state = findState(country, addressLines);

  return {
    state,
    country,
    company,
    id: value.place_id,
    types: value.types,
    label: addressLines.join(', '),
    address: addressLines
      // Remove company name and other excess from the address
      .filter((line) => [company, state, country].includes(line) === false)
      .join(', '),
    zip,
    phone
  };
}

function getMilesRadius(miles) {
  return miles * 1609.344;
}

function getPlaceDetailsByPlaceId(placeId) {
  return new Promise((resolve) => {
    const service = new window.google.maps.places.PlacesService(document.createElement('div'));
    service.getDetails({ placeId, fields: ['formatted_phone_number'] }, (placeDetail) =>
      resolve(placeDetail)
    );
  });
}

const DEFAULT_LOCATION = '40.71,-74.0';
const DEFAULT_TYPES = ['establishment'];

async function getCurrentLocation() {
  if (NODE_ENV === 'test') return DEFAULT_LOCATION;

  return new Promise((res) =>
    navigator.geolocation.getCurrentPosition(
      // Expected format is comma separated
      ({ coords }) => res([coords.latitude, coords.longitude].join(' ,'))
    )
  );
}

function LocationSearch({ field, form }) {
  const { placeholder, id, name, types = DEFAULT_TYPES, property, radius = 10 } = field;
  const defaultValue = form.watch(name, field.defaultValue) || null;
  const { lang } = useTranslation();

  const [location, setLocation] = useState(DEFAULT_LOCATION);
  const [selectValue, setSelectValue] = useState(defaultValue);

  useEffect(() => {
    getCurrentLocation().then(setLocation);
  }, []);

  return (
    <SearchWrapper
      data-testid="test-LocationSearch"
      aria-label={field.label}
      form={form}
      field={field}
    >
      <Controller
        name={name}
        control={form.control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            apiKey={API_KEY}
            autocompletionRequest={{
              types,
              // Use browser location prompt
              location,
              radius: getMilesRadius(radius)
            }}
            selectProps={{
              id,
              'instanceId': id,
              'value': selectValue,
              placeholder,
              'defaultValue': value,
              'aria-label': value ? value.label : 'Type something to locate your business',
              'required': isRequired(field),
              'noOptionsMessage': () => (lang === 'hk' ? '没有选择' : 'No options'),
              'onChange': async (place) => {
                setSelectValue(place);
                // To something like this:
                const reactGooglePlacesAutocomplete = await import(
                  /* webpackExports: "geocodeByPlaceId" */ 'react-google-places-autocomplete'
                );
                const geocodeByPlaceId = reactGooglePlacesAutocomplete.geocodeByPlaceId;
                const geocode = await geocodeByPlaceId(place.value.place_id);
                const details = await getPlaceDetailsByPlaceId(place.value.place_id);
                // User wants to select a particular property from
                // the location result (such as "label", or "country")
                if (property) {
                  return onChange(
                    get(property, buildPlace(place, geocode, details?.formatted_phone_number))
                  );
                }

                return onChange(buildPlace(place, geocode, details?.formatted_phone_number));
              },
              'styles': {
                menu: (provided) => ({
                  ...provided,
                  color: '#260212'
                })
              }
            }}
          />
        )}
      />
    </SearchWrapper>
  );
}

LocationSearch.propTypes = fieldPropTypes;

export default LocationSearch;
