import React from 'react';
import styled from 'styled-components';
import FieldPropTypes from './PropTypes';
import Checkbox from './FieldTypes/Checkbox';
import Date from './FieldTypes/Date';
import DateRange from './FieldTypes/DateRange';
import ReCaptcha from './FieldTypes/ReCaptcha';
import DefaultField from './FieldTypes/Default';
import Hidden from './FieldTypes/Hidden';
import LocationSearch from './FieldTypes/LocationSearch';
import Select from './FieldTypes/Select';
import Radio from './FieldTypes/Radio';
import SelectCountry from './FieldTypes/SelectCountry';
import SelectState from './FieldTypes/SelectState';
import TimeRange from './FieldTypes/TimeRange';
import BusinessSearch from '../components/ModuleForm/FieldTypes/BusinessSearch';
import TextToggle from '../components/ModuleForm/FieldTypes/TextToggle';
import Toggleable from '../components/ModuleForm/FieldTypes/Toggleable';
import Spacer from '../components/ModuleForm/FieldTypes/Spacer';
import Password from './FieldTypes/Password';

const FieldWrapper = styled.div`
  box-sizing: content-box;

  ${({ isHidden }) =>
    isHidden &&
    `
      display: none;
      width: 0;
      max-width: 0;
    `}
`;

const componentRegistry = {
  'hidden': Hidden,
  'color': DefaultField,
  'date': Date,
  'time-range': TimeRange,
  'date-range': DateRange,
  'checkbox': Checkbox,
  'location-search': LocationSearch,
  'datetime': DefaultField,
  'email': DefaultField,
  'file': DefaultField,
  'image': DefaultField,
  'month': DefaultField,
  'number': DefaultField,
  'password': Password,
  'radio': Radio,
  'range': DefaultField,
  'required': DefaultField,
  'reset': DefaultField,
  'search': DefaultField,
  'string': DefaultField,
  'submit': DefaultField,
  'tel': DefaultField,
  'text': DefaultField,
  'time': DefaultField,
  'url': DefaultField,
  'week': DefaultField,
  'button': DefaultField,
  'select': Select,
  'state': SelectState,
  'country': SelectCountry,
  'business-search': BusinessSearch,
  'spacer': Spacer,
  'toggleable': Toggleable,
  'text-toggle': TextToggle,
  'recaptcha': ReCaptcha
};

/**
 * Allow users of this module to register their own
 * custom components which will function in the same
 * way as regular components.
 *
 * For more information see DynamicForm.md
 * */
export function registerComponent(name, component) {
  componentRegistry[name] = component;
}

export function unregisterComponent(name) {
  delete componentRegistry[name];
}

/** Returns the currently selected component type based on field.type */
export function getComponent(field) {
  const { hidden } = field;
  if (hidden) {
    return Hidden;
  }

  const { type } = field;
  if (componentRegistry[type]) {
    return componentRegistry[type];
  }

  // eslint-disable-next-line no-console
  console.error(`Field type ${type} not found!`, field);
  return DefaultField;
}

/**
 * Renders a field of different types, this also supports
 * checking if the component should render at the first moment
 * by adding a Component.willRender = fn(props) => Bool
 * */
function FieldOfType({ field, form, className, customized, sectionBgColor, inline }) {
  const Component = getComponent(field);

  const isHidden = field.hidden || field.type === 'hidden';

  /**
   * We allow components to tag themselves with a `willRender`
   * functionality allowing bypassing of any field wrapper styles
   * */
  if (Component.willRender instanceof Function) {
    if (Component.willRender({ field, form }) !== true) {
      return null;
    }
  }

  return (
    <FieldWrapper isHidden={isHidden} className={className}>
      <Component
        field={field}
        form={form}
        customized={customized}
        sectionBgColor={sectionBgColor}
        inline={inline}
      />
    </FieldWrapper>
  );
}

FieldOfType.propTypes = {
  ...FieldPropTypes
};
FieldOfType.defaultFieldProps = {};

export default FieldOfType;
