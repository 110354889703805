/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import dynamic from 'next/dynamic';

import 'react-datepicker/dist/react-datepicker.css';
import { Controller } from 'react-hook-form';

import fieldPropTypes from '../PropTypes';

import { isRequired } from './utils';
import { getLabel } from './LabelWrapper';

import { DateWrapper } from './styles';

const DatePicker = dynamic(() => import('react-datepicker'));
function DateRange({ field, form }) {
  const { placeholder, id, name, sublabel, defaultValue } = field;

  return (
    <Controller
      id={id}
      name={name}
      control={form.control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <DateWrapper data-testid="test-Date">
          <label htmlFor={id}>{getLabel({ field, form })}</label>
          {sublabel && <span>{sublabel}</span>}
          <DatePicker
            onChange={(date) => onChange(date)}
            minDate={new Date()}
            startDate={value}
            placeholder={placeholder}
            required={isRequired(field)}
            inline
          />
        </DateWrapper>
      )}
    />
  );
}

DateRange.propTypes = fieldPropTypes;

export default DateRange;
