import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import clone from 'lodash/clone';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import debounce from 'lodash/debounce';

// TODO: Remove these long import paths
import { useSchema, useValidator } from '../utils/forms';
import { generateSteps, generateFields, generateFieldsUntilStep } from './dependsOn';

/**
 * Allows us to trigger form validation with a debounce
 * Calling this multiple times will result in the form validating
 * after some delay from the last call.
 * */
const debouncedTrigger = debounce((form) => form.trigger(), 500);

/**
 * By passing in an array of JSON steps as per our form schema
 * we'll get back a dynamic version of those steps along with a form.
 *
 * The steps will be built based on the form state, and will update
 * each time a field is changed.
 */
export default function useDynamicForm(
  steps,
  { inline = true, step: initialStep = 0, defaultValues = {} } = {}
) {
  // We'll save form values here because they seem to get lost
  // when we update the form schema. Normally we're moving to the
  // next step and generating a new schema based on user input
  // this seems to cause the formValues to be lost
  const formValues = useRef(defaultValues);

  const [step, setStep] = useState(initialStep);
  const [schema, setSchema] = useSchema(
    // Start with all fields
    // we'll update this when the dynamic form receives some
    // new information about which fields should be shown. See below
    steps.slice(0, step + 1).reduce((acc, { fields }) => acc.concat(fields), [])
  );

  const validator = useValidator(schema);
  const form = useForm({
    mode: 'onChange',
    resolver: validator,
    defaultValues: clone(formValues.current)
  });

  const currentFormState = form.getValues();
  const currentSteps = generateSteps(steps, currentFormState);

  // Force revalidation of the new form fields
  // React might take some time to remount the form fields
  // so we'll wait a little bit and after the state has settled
  // we'll trigger the form to revalidate.
  useEffect(() => {
    if (isEqual(formValues.current, currentFormState)) return;
    debouncedTrigger(form);
  }, [currentFormState]);

  /**
   * Builds the schema for our current step
   * or all steps if we're `inline`
   */
  useEffect(() => {
    // We'll use a check here to prevent from refreshing the
    // schema on every load. This is because when we regenerate
    // schema we'll also get a new set of formValues
    if (isEqual(formValues.current, currentFormState)) return;
    formValues.current = currentFormState;

    // Build our schema for the current formState
    // this allows us to have steps with dependencies on other
    // parts of the formstate. I.e. having a page which is toggled
    // by something else in the form.
    let currentFieldSet;

    // NOTE: It's important that we ask for new form values
    //       when finding out the field set, as for each step
    //       we might have new conditional fields mounted.
    //
    //       I've used this in the past to save intermediate form
    //       calculations to prevent excess dependsOn logic.

    if (inline) {
      // We're not using steps, just generate everything possible
      currentFieldSet = generateFields(currentSteps, currentFormState);
    } else {
      // We are using steps to build out the schema
      // one step at a time as the user advances through the
      // application. This will allow generation of the schema up to current step
      currentFieldSet = generateFieldsUntilStep(currentSteps, step + 1, currentFormState);
    }

    setSchema(currentFieldSet);
  }, [
    steps,

    // TODO: We can increase performance of this
    //       by filtering out all fields which are dependedOn
    //       and only watching those to rebuild the schema
    form.watch()
  ]);

  // Apply validation by default whenever we call setValue
  const { setValue: oldSetValue } = form;
  const setValue = (name, value, options = {}) => {
    if (isEqual(value, currentFormState[name])) return;
    oldSetValue(name, value, merge({ shouldValidate: true }, options));
  };

  /**
   * Ensure that react-hook-form combines hidden values
   * By default these are not provided to onSubmit
   */
  const handleSubmitWrapper = (onSubmitFunc, onErrorFunc) => async (event) => {
    event.preventDefault();
    try {
      return await onSubmitFunc(form.getValues(), event);
    } catch (error) {
      return onErrorFunc(error, event);
    }
  };

  return {
    form: merge(form, {
      setValue,
      handleSubmit: handleSubmitWrapper
    }),
    schema,
    validator,
    setStep,
    step,
    steps: currentSteps
  };
}
