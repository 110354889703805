import React from 'react';
import dynamic from 'next/dynamic';
import { Controller } from 'react-hook-form';

import fieldPropTypes from '../PropTypes';
import { ReCaptchaWrapper } from './styles';

const ReCAPTCHA = dynamic(() => import('react-google-recaptcha'));

const recaptchaKey = process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_KEY;

function ReCaptchaField({ field, form }) {
  const { id, name, defaultValue } = field;

  return (
    <Controller
      id={id}
      name={name}
      control={form.control}
      defaultValue={[defaultValue, defaultValue]}
      render={({ field: { onChange } }) => (
        <ReCaptchaWrapper data-testid="test-Recaptcha">
          <ReCAPTCHA
            sitekey={recaptchaKey}
            onChange={(recaptchaToken) => {
              onChange(!!recaptchaToken);
            }}
          />
        </ReCaptchaWrapper>
      )}
    />
  );
}

ReCaptchaField.propTypes = fieldPropTypes;

export default ReCaptchaField;
