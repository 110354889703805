/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import 'react-datepicker/dist/react-datepicker.css';
import addMinutes from 'date-fns/addMinutes';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import setMinutes from 'date-fns/setMinutes';
import setHours from 'date-fns/setHours';
import { Controller } from 'react-hook-form';
import fieldPropTypes from '../PropTypes';

import { isRequired } from './utils';
import { getLabel } from './LabelWrapper';

const DatePicker = dynamic(() => import('react-datepicker'));

const DateFields = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1.25rem;

  > div {
    margin-right: 1.25rem;
  }
`;

const TimeRangeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
`;

function buildTime(date) {
  if (!date) return null;
  return `${getHours(date).toString().padStart(2, '0')}:${getMinutes(date)
    .toString()
    .padEnd(2, '0')}`;
}

function TimeRange({ field, form }) {
  const { placeholder, id, name, sublabel } = field;

  const [startTime, setStartTime] = useState(null);
  const [finishTime, setFinishTime] = useState(null);

  let { name_start: startName, name_finish: finishName } = field;
  if (!startName) startName = `${name}.start`;
  if (!finishName) finishName = `${name}.finish`;

  const { defaultValue = null } = field;

  return (
    <TimeRangeWrapper data-testid="test-TimeRange">
      <label htmlFor={id}>{getLabel({ field, form })}</label>
      {sublabel && <span>{sublabel}</span>}
      <DateFields>
        <Controller
          id={id}
          name={startName}
          control={form.control}
          defaultValue={buildTime(defaultValue)}
          render={({ field: { onChange } }) => (
            <DatePicker
              selected={startTime}
              onChange={(newValue) => {
                setStartTime(newValue);
                setFinishTime(null);
                onChange(buildTime(newValue));
              }}
              showTimeSelect
              showTimeSelectOnly
              timeFormat="HH:mm"
              timeIntervals={30}
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
              placeholder={placeholder}
              required={isRequired(field)}
              inline
            />
          )}
        />
        <Controller
          id={id}
          name={finishName}
          control={form.control}
          defaultValue={buildTime(defaultValue)}
          render={({ field: { onChange } }) => (
            <DatePicker
              selected={finishTime}
              onChange={(newValue) => {
                setFinishTime(newValue);
                onChange(buildTime(newValue));
              }}
              minTime={startTime && addMinutes(startTime, 30)}
              maxTime={startTime && setHours(setMinutes(new Date(), 45), 23)}
              showTimeSelect
              showTimeSelectOnly
              timeFormat="HH:mm"
              timeIntervals={30}
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
              placeholder={placeholder}
              required={isRequired(field)}
              inline
            />
          )}
        />
      </DateFields>
    </TimeRangeWrapper>
  );
}

TimeRange.propTypes = fieldPropTypes;

export default TimeRange;
