import React from 'react';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import fieldPropTypes from '../PropTypes';
import LabelWrapper from './LabelWrapper';

const RadioWrapper = styled(LabelWrapper)`
  span {
    display: flex;
    align-items: center;
  }

  div[class$='-placeholder'] ~ div[class^='css-'] {
    padding-top: 0;
    padding-bottom: 0;
  }

  input {
    margin: 0.4rem 0;
    padding: 0;
  }
`;

function RadioField({ field, form }) {
  const { name, options } = field;
  const defaultString = form.watch(name, field.defaultValue);
  const defaultValue = options.find(({ value }) => value === defaultString);
  const defaultControlValue = defaultValue ? defaultValue.value : null;

  return (
    <RadioWrapper data-testid="test-Radio" field={field} name={name} form={form}>
      <Controller
        name={name}
        control={form.control}
        defaultValue={defaultControlValue}
        render={({ field: { onChange } }) => (
          <>
            {options.map((option) => (
              <div>
                <input
                  name={name}
                  id={`${name}-${option.value}`}
                  type="radio"
                  value={option.value}
                  onChange={(event) => {
                    onChange(event.target.value);
                  }}
                />
                <label htmlFor={`${name}-${option.value}`}>&nbsp;&nbsp;{option.label}</label>
              </div>
            ))}
          </>
        )}
      />
    </RadioWrapper>
  );
}

RadioField.propTypes = fieldPropTypes;

export default RadioField;
