// Colors
export const colors = {
  black: '#000',
  nearBlack: '#260212',
  blue: '#4F0423',
  lightBlue: '#e10600',
  red: '#4F0423',
  grey: '#e5e5e5',
  lightGrey: '#f6f6f6',
  mediumGrey: '#8f8f8f',
  darkGrey: '#4f0423',
  offWhite: '#f0f0f0',
  yellow: '#ffcd00',
  greenAlt: '#00dea6',
  purpleAlt: '#CC99FF',

  // colors from old repo
  alabaster: '#f7f7f7',
  alto: '#d0d0d0',
  amethyst: '#a44fcf',
  blueRibbon: '#0058ff',
  bonJour: '#e5e0e1',
  brightTurquoise: '#07cbff',
  bastille: '#201c28',
  boulder: '#7d7d7d',
  cararra: '#e6e6dc', // Brand color approved
  cardinal: '#bb203b',
  caribbeanGreen: '#00dea6', // Brand color approved
  caribbeanGreenOpaque: '#00ce9a',
  concrete: '#f2f2f2',
  cotonCandy: '#ffb6d5',
  cyan: '#00ffff',
  darkCharcoal: '#333333',
  darkGray: '#434343',
  doveGray: '#696969',
  dustyGray: '#9a9999',
  eyebrow: '#8f8f8f',
  flamingo: '#f26522',
  fordGray: '#979797',
  fuelYellow: '#f1ad27',
  gallery: '#eeeeee',
  ghost: '#cdcdd5',
  gray: '#888888',
  jade: '#00a279',
  lightGray: '#dadada',
  mercury: '#e5e5e5',
  mineShaft: '#260212',
  mintTulip: '#beeff1',
  monaLisa: '#ffa58a', // Brand color approved
  moonMist: '#e0e2d6',
  pattensBlue: '#dcf4ff',
  persianGreen: '#00b285',
  pinkLace: '#ffd1f2',
  purple: '#a582e3', // Brand color approved
  purpleDarken: '#9b74e0',
  radicalRed: '#4F0423', // Brand color approved
  radicalRedDarken: '#ff2c50',
  roseBudCherry: '#870d4f', // Brand color approved
  rose: '#f00074', // Instagram
  sanMarino: '#3b63a6', // Facebook
  silver: '#cccccc',
  silverChalice: '#a1a1a1',
  silverChaliceDark: '#a4a4a4',
  royalBlue: '#4F0423', // Brand color approved
  silverLight: '#cbcbcb',
  solitude: '#ebf8ff',
  springWood: '#f6f7f1',
  supernova: '#ffcd00', // Brand color approved
  transparent: 'transparent',
  tuna: '#4f0423', // Brand color approved
  white: '#ffffff',
  whiteApprox: '#fefefe',
  wildSand: '#f6f6f6',
  wildSandSolid: '#ffc7c6',
  persimmon: '#ff6150',
  darkRed: '#Bf0000'
};

export const fonts = {
  // Fonts
  headline: 'SansMeatCompressedBold',
  subhead: 'SansMeatCompressedRegular',
  standard: 'SansMeatProportional',
  standardBold: 'SansMeatProportionalBold',
  scienceHeadline: 'SansMeatCompressedBold, sanErif',
  scienceSubhead: 'SansMeatCompressedRegular, sanErif'
};
