/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import identity from 'lodash/fp/identity';

import fieldPropTypes from '../PropTypes';

const HiddenInput = styled.input`
  display: none;
  width: 0;
  max-width: 0;
`;

/**
 * Some fields render subfields
 * We should make sure to return their
 * subfield name so that we can generate a hidden
 * field for it also
 * */
function subfieldName({ field, type }) {
  // Sometimes we have a subfield
  // Example would be the `Toggleable` field type
  // in this case we should be sure to inject the child
  // field name into the state as well
  if (field instanceof Object) {
    return field.name;
  }

  // Some fields render arbitrary subfields
  // we should add them here
  switch (type) {
    case 'business-search':
      return 'customBusiness';

    default:
      return null;
  }
}

function Hidden({ field, form }) {
  const { id, name, type, value } = field;

  const names = [name, subfieldName(field)].filter(identity);

  // Building up our set of values from the names
  // This should include the values from our main field
  // and all subfields
  const values = []
    .concat(value || form.watch(name, false))
    .concat(names.slice(1).map((subName) => form.watch(subName, false)));

  useEffect(() => {
    const oldValues = form.getValues();

    names
      .filter((currentName, index) => oldValues[name] !== values[index])
      .forEach((currentName, index) => {
        // Ensure we have the value saved in the form state
        form.setValue(currentName, values[index]);
      });
  }, [names]);

  return (
    <div data-testid="test-Hidden">
      {names.map((currentName, index) => (
        <Controller
          type={type}
          name={currentName}
          render={({ field: aField }) => <HiddenInput {...aField} />}
          control={form.control}
          id={`${id}-${index}`}
          key={`key-${currentName}`}
          // We set the value
          // these fields are mostly used for previous steps
          // in the dynamic form where we need to preserve
          // user inputs from the last steps.
          //
          // WARNING: If we don't have a value here
          //          we can get stuck in an infinite loop where
          //          the form is updating and then this field gets rebuilt
          defaultValue={values[index]}
        />
      ))}
    </div>
  );
}

Hidden.propTypes = fieldPropTypes;

export default Hidden;
