import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import jsonLogic from 'json-logic-js';
import cx from 'classnames';

import { fonts, colors } from '../../styles';
import { isRequired } from './utils';
import { FieldProp, FormProp } from '../PropTypes';
import TradeMark from '../../generics/TradeMark';

export const ErrorMessage = styled.span`
  display: block;
  max-width: 400px;
  margin: 0.4rem 0 0 auto;
  color: ${colors.darkRed};
  font-family: ${fonts.standard};
  font-size: 0.9rem;
  text-align: right;
  animation: 0s ease 0s 1 normal none running none;
  word-break: break-word;
`;

const ErrorWrapper = styled.div`
  ${({ hasError }) =>
    hasError &&
    `input {
      margin-bottom: 0.4rem;
      padding-bottom: 0.8rem;
      border-width: 0.2rem;
      border-color: ${colors.red};
    }
  `}
`;

export function getLabel({ field = {}, form }) {
  const { label } = field;

  // Defaults to no label swapping
  if (typeof label === 'string') return <TradeMark>{label}</TradeMark>;

  /**
   * Allows us to apply logic to the label field
   * we can change the label depending on toggle state
   * for example or perhaps give some prompt to the user
   * about the value they just input. Check mocks for example.
   */
  return jsonLogic.apply(field.label, form.getValues());
}

function LabelWrapper({ form, field, children, ref, className, ...dataProps }) {
  const { id, name, label } = field;

  const hasError = form.formState.touchedFields[name] && form.formState.errors[name];
  const errorMessage = typeof hasError === 'object' ? hasError.message : hasError;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ErrorWrapper ref={ref} className={cx(className, 'error-wrapper')} {...dataProps}>
      {label ? (
        <label
          className="p-0 label-main d-inline-flex"
          style={{ gap: 4 }}
          id={`label-${id}`}
          htmlFor={`${id}`}
        >
          {getLabel({ form, field })}
          {isRequired(field) && '*'}
        </label>
      ) : (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label className="p-0 sr-only sr-only-focusable" id={`label-${id}`} htmlFor={label}>
          {field.type}
        </label>
      )}
      {children}
      {errorMessage && <ErrorMessage id={`error-${id}`}>{`Error: ${errorMessage}`}</ErrorMessage>}
    </ErrorWrapper>
  );
}

const MergedPropTypes = {
  ref: PropTypes.func,
  form: FormProp.isRequired,
  className: PropTypes.string,
  field: FieldProp.isRequired,
  children: PropTypes.node.isRequired
};

LabelWrapper.propTypes = MergedPropTypes;
LabelWrapper.defaultProps = { ref: undefined, className: '' };

export default LabelWrapper;
