import React from 'react';
import fieldPropTypes from '../PropTypes';

import Select from './Select';
import States from './States';

const mergeStateOptions = (field) => ({
  ...field,
  options: Object.entries(States).map(([stateKey, state]) => ({
    label: state,
    value: stateKey
  }))
});

function SelectState({ field, form, customized }) {
  return <Select form={form} field={mergeStateOptions(field)} customized={customized} />;
}

SelectState.propTypes = fieldPropTypes;

export default SelectState;
