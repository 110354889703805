import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UnderlineInput from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  underlineInput: {
    'borderWidth': '0px',
    'borderBottomWidth': '1px',
    '& .MuiInput-underline': {
      padding: 0
    }
  }
}));

export const PasswordPropTypes = {
  field: PropTypes.shape({
    placeholder: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string
  }),
  form: PropTypes.shape({
    control: PropTypes.string,
    register: PropTypes.string,
    formState: PropTypes.arrayOf(
      PropTypes.shape({
        touchedFields: PropTypes.bool,
        errors: PropTypes.string
      })
    )
  })
};

function PasswordComponent({ field, form }) {
  const { control, register } = form;
  const { placeholder, id, name, label } = field;
  const [showPassword, setShowPassword] = useState(false);

  const classes = useStyles();

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const hasError = form.formState.touchedFields[name] && form.formState.errors[name];
  const errorMessage = typeof hasError === 'object' ? hasError.message : hasError;

  return (
    <>
      <Controller
        id={id}
        name={name}
        control={control}
        type="password"
        rules={{ required: true }}
        render={() => (
          <UnderlineInput
            {...register(name)}
            id={id}
            name={name}
            placeholder={placeholder}
            classes={{
              underlineInput: classes.underlineInput,
              focused: classes.focused
            }}
            type={showPassword ? 'text' : 'password'}
            style={{
              width: '100%',
              borderRadius: '0px',
              background: '#e7f0fe',
              padding: 0,
              marginBottom: 20
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label={label}
          />
        )}
      />
      {errorMessage && (
        <FormHelperText style={{ fontSize: '0.9rem', textAlign: 'right', color: '#4F0423' }}>
          {errorMessage}
        </FormHelperText>
      )}
    </>
  );
}

PasswordComponent.propTypes = PasswordPropTypes;

PasswordComponent.defaultProps = {
  field: {
    placeholder: 'Enter your password',
    id: 'password',
    name: 'password',
    label: 'Password'
  },
  form: {}
};

export default PasswordComponent;
