/* eslint-disable react/prop-types */
import React from 'react';
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import fieldPropTypes from '../PropTypes';
import LabelWrapper from './LabelWrapper';
import { isRequired } from './utils';

const Select = dynamic(() => import('react-select'));

const SelectWrapper = styled(LabelWrapper)`
  span {
    display: flex;
    align-items: center;
  }

  div[class$='-placeholder'] ~ div[class^='css-'] {
    padding-top: 0;
    padding-bottom: 0;
  }

  div[class$='-placeholder'] {
    color: #000000;
  }

  input {
    margin: 0.8rem 0;
    padding: 0;
  }

  // Fix a bug where the select items are white on white
  > div > div:nth-of-type(2) {
    div {
      color: black;
    }
  }
`;

function SelectField({ field, form }) {
  const { name, options, isMulti, label } = field;
  const { lang } = useTranslation();
  const defaultString = form.watch(name, field.defaultValue);
  const defaultValue = options.find(({ value }) => value === defaultString);
  const defaultControlValue = defaultValue ? defaultValue.value : null;
  const { menuPortalTargetBody = true } = field;
  const body = global.document && global.document.body;

  return (
    <SelectWrapper data-testid="test-Select" field={field} name={name} form={form}>
      <Controller
        name={name}
        control={form.control}
        defaultValue={defaultControlValue}
        render={({ field: { onChange } }) => (
          <Select
            id={name}
            instanceId={name}
            isMulti={isMulti}
            closeMenuOnSelect={!isMulti}
            hideSelectedOptions={false}
            placeholder={lang === 'hk' ? '选择...' : 'Select...'}
            options={options}
            defaultValue={defaultValue}
            aria-required={isRequired(field)}
            aria-label={`Select ${label}`}
            aria-expanded="true"
            menuPortalTarget={menuPortalTargetBody ? body : null}
            onChange={(event) => {
              let value = (event && event.value) || '';
              if (isMulti) {
                value = event.map((x) => x.value);
                value = value.join(';');
              }
              onChange(value);
            }}
          />
        )}
      />
    </SelectWrapper>
  );
}

SelectField.propTypes = fieldPropTypes;

export default SelectField;
