import PropTypes from 'prop-types';

export const FieldProp = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
  customized: PropTypes.bool,

  label: PropTypes.oneOfType([
    // Allows us to use jsonLogic to generate the label
    // based on the current value in the field
    PropTypes.object,
    PropTypes.string.isRequired
  ]),
  validations: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      params: PropTypes.arrayOf(
        PropTypes.oneOfType([
          // see utils/form.js
          PropTypes.number,
          PropTypes.string,
          PropTypes.array
        ])
      )
    })
  )
});

export const FormProp = PropTypes.shape({
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired
});

export const DynamicForm = PropTypes.shape({
  step: PropTypes.number,
  form: FormProp.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object)
});

export default {
  form: FormProp.isRequired,
  field: FieldProp.isRequired
};
