import React from 'react';
import fieldPropTypes from '../PropTypes';

import { isRequired } from './utils';
import { InputWrapper, InputUnderline } from './styles';
import LabelWrapper from './LabelWrapper';

function Default({ field, form }) {
  const { register } = form;
  const { placeholder, id, name, type, icon } = field;

  return (
    <LabelWrapper form={form} field={{ ...field, id }}>
      <InputWrapper hasIcon={!!icon} className="input-wrapper">
        {icon && <div>{icon}</div>}
        <InputUnderline
          id={id}
          type={type}
          name={name}
          {...register(name)}
          className="input-underline"
          tabIndex="0"
          data-testid="test-Default"
          placeholder={placeholder}
          required={isRequired(field)}
          aria-required={isRequired(field)}
          aria-labelledby={`label-${id}`}
          aria-label={`Write down ${name}`}
          aria-describedby={`label-${id}`}
          defaultValue={form.watch(name, field.defaultValue)}
        />
      </InputWrapper>
    </LabelWrapper>
  );
}

Default.propTypes = fieldPropTypes;

export default Default;
