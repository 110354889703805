import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import fieldPropTypes from '../../../DynamicForm/PropTypes';
import Default from '../../../DynamicForm/FieldTypes/Default';
import LocationSearch from '../../../DynamicForm/FieldTypes/LocationSearch';

const useStyles = makeStyles((theme) => ({
  button: {
    fontFamily: 'SansMeatProportional, sans-serif',
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontSize: '0.9rem'
  }
}));

function customBusinessField({ id, name = 'company', label = 'Establishment Name' }, lang = 'en') {
  if (lang === 'hk')
    return {
      id,
      name,
      label,
      type: 'text',
      placeholder: '在此輸入',
      schema: {
        messages: {
          stringEmpty: '請輸入公司名稱'
        },
        type: 'string',
        required: true
      }
    };
  return {
    id,
    name,
    label,
    type: 'text',
    placeholder: 'Enter here',
    schema: {
      messages: {
        stringEmpty: 'Please enter an establishment name'
      },
      type: 'string',
      required: true
    }
  };
}

function customBusinessLabel(isCustomBusiness, lang = 'en') {
  if (isCustomBusiness && lang === 'hk') return '搜索您的公司';
  if (isCustomBusiness) return 'Search for your business instead';
  if (lang === 'hk') return '看不到您的公司吗？';
  return "Can't find your business?";
}

function BusinessSearch({ field, form }) {
  const [openButton, setOpenButton] = useState();
  const isCustomBusiness = form.getValues().customBusiness;
  const { lang } = useTranslation();
  const classes = useStyles();
  const customBussinessField = React.useMemo(() => customBusinessField(field, lang), [field, lang]);

  return (
    <>
      {isCustomBusiness ? (
        <Default form={form} field={customBussinessField} />
      ) : (
        <LocationSearch form={form} field={customBussinessField} />
      )}
      <Controller
        control={form.control}
        defaultValue={false}
        name="customBusiness"
        render={({ field: { onChange, value } }) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button
              className={classes.button}
              tabIndex="0"
              aria-pressed={openButton}
              aria-label={
                openButton
                  ? 'Search for your business instead'
                  : "Can't find your business?, if you press this button a new form will open at the bottom of this page, in order to submit the information required of your business"
              }
              onClick={() => {
                const nextValue = !value;
                // We should clear the object from the formValues
                // otherwise we'll end up rendering the object into the TextField above
                if (nextValue) {
                  form.resetField(field.name);
                }
                setOpenButton(nextValue);
                onChange(nextValue);
              }}
            >
              {customBusinessLabel(isCustomBusiness, lang)}
            </Button>
          </div>
        )}
      />
    </>
  );
}

BusinessSearch.propTypes = fieldPropTypes;

export default BusinessSearch;
