/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Controller } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import fieldPropTypes from '../PropTypes';

import { isRequired, findBool } from './utils';
import { getLabel } from './LabelWrapper';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 8px 8px 0px',
    [theme.breakpoints.down('md')]: {
      marginTop: '8px'
    }
  },
  checkbox: {
    'marginRight': '16px',
    'height': '100%',
    "&[class*='Mui-focusVisible']": {
      "& [class*='MuiTouchRipple-root']": {
        backgroundColor: '#4F042380'
      }
    }
  },
  typography: ({ sectionBgColor }) => {
    return {
      color: theme.palette.text.primary,
      marginBottom: '0 !important',
      fontSize: '0.9rem !important',
      textAlign: 'start',
      [theme.breakpoints.down('md')]: {
        textAlign: 'left'
      },
      ...((sectionBgColor === '#09f' ||
        sectionBgColor === '#CC99FF' ||
        sectionBgColor === '#4F0423') && {
        color: 'white'
      })
    };
  }
}));

function InputTypeCheckbox({ field, form, sectionBgColor }) {
  const { placeholder, id, name, type } = field;
  const classes = useStyles({ sectionBgColor });
  const uniqueId = name;
  const inputId = `${id}-${uniqueId}`;

  return (
    <Controller
      id={id}
      name={name}
      type="checkbox"
      control={form.control}
      defaultValue={findBool(form.watch(name, field.defaultValue))}
      render={({ field: { onChange, value } }) => {
        const toggle = () => onChange(!value);

        return (
          <Box className={classes.box}>
            <Checkbox
              color="primary"
              id={inputId}
              type={type}
              name={name}
              tabIndex="0"
              checked={value}
              onChange={toggle}
              onClick={toggle}
              disableFocusRipple
              className={classes.checkbox}
              data-testid="test-Checkbox"
              placeholder={placeholder}
              required={isRequired(field)}
              inputProps={{
                'aria-labelledby': `label-${inputId}`,
                'aria-required': isRequired(field)
              }}
            />
            <label htmlFor={inputId} id={`label-${inputId}`}>
              <Typography className={classes.typography} role="presentation" htmlFor={id}>
                {getLabel({ field, form })}
              </Typography>
            </label>
          </Box>
        );
      }}
    />
  );
}

InputTypeCheckbox.propTypes = fieldPropTypes;

export default InputTypeCheckbox;
